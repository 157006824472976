import React, { useState, useEffect } from 'react';
import { fetchMeetings, fetchMultiLevelAnalysis } from '../services/api';
import { Line, Bar, Scatter, Doughnut, Pie, Radar, PolarArea } from 'react-chartjs-2';
import 'chart.js/auto';

const MultiLevelAnalysis = () => {
    const [meetings, setMeetings] = useState([]);
    const [selectedMeeting, setSelectedMeeting] = useState('');
    const [selectedPeriod, setSelectedPeriod] = useState('');
    const [analysisData, setAnalysisData] = useState(null);
    const [error, setError] = useState('');

    const periods = ['week', 'month', 'year', 'year_to_date', '3_months', '6_months', 'all_time'];

    useEffect(() => {
        const loadMeetings = async () => {
            try {
                const response = await fetchMeetings();
                setMeetings(response.data);
            } catch (error) {
                console.error('Error fetching meetings:', error);
            }
        };
        loadMeetings();
    }, []);

    const handleMeetingChange = (event) => {
        setSelectedMeeting(event.target.value);
        setError('');
    };

    const handlePeriodChange = (event) => {
        setSelectedPeriod(event.target.value);
        setError('');
    };

    useEffect(() => {
        const fetchAnalysis = async () => {
            try {
                const response = await fetchMultiLevelAnalysis(selectedMeeting, selectedPeriod);
                setAnalysisData(response.data);
                setError('');
            } catch (error) {
                console.error('Error fetching multi-level analysis:', error);
                setError('Error fetching multi-level analysis: ' + (error.response ? error.response.data.error : error.message));
            }
        };

        if (selectedMeeting && selectedPeriod) {
            fetchAnalysis();
        }
    }, [selectedMeeting, selectedPeriod]);

    const data = {
        labels: analysisData ? analysisData.periods : [],
        datasets: [
            {
                label: 'Total Attendance for the Selected Period',
                data: analysisData ? analysisData.attendance_counts : [],
                backgroundColor: 'rgba(60, 175, 45, 0.37)',
                borderColor: 'rgb(40, 183, 104)',
                borderWidth: 4
            }
        ]
    };

    return (
        <div>
            <h2>Multi-Level Analysis</h2>
            <div>
                <label>Select Meeting: </label>
                <select value={selectedMeeting} onChange={handleMeetingChange}>
                    <option value="">-- Select a Meeting --</option>
                    {meetings.map(meeting => (
                        <option key={meeting.meeting_id} value={meeting.meeting_name}>
                            {meeting.meeting_name}
                        </option>
                    ))}
                </select>
            </div>
            <div>
                <label>Select Period: </label>
                <select value={selectedPeriod} onChange={handlePeriodChange}>
                    <option value="">-- Select a Period --</option>
                    {periods.map(period => (
                        <option key={period} value={period}>
                            {period.replace('_', ' ')}
                        </option>
                    ))}
                </select>
            </div>
            {error && <p style={{color: 'red'}}>{error}</p>}
            <div>
                {analysisData && <Bar data={data} />}
                {analysisData && <Line data={data} />}
                {analysisData && <Pie data={data} />}
            </div>
        </div>
    );
};

export default MultiLevelAnalysis;
